const userModal = document.getElementById('userModal')
if (userModal) {
  const userDisableForm = userModal.querySelector('form[name="disable_user"]')
  const tokenInput = document.getElementById('disable_user__token')
  const idInput = document.getElementById('disable_user_id')
  userModal.addEventListener('show.bs.modal', (event) => {
    const btnData = event.relatedTarget.dataset
    userDisableForm.action = btnData.userDisablePath
    idInput.value = btnData.userId
    tokenInput.value = btnData.userDisableToken
    userModal.querySelectorAll('.user-name').forEach((item) => {
      item.innerHTML = btnData.userName
    })
  })
}
